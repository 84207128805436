<template>
    <div class="box parties">
        <div class="box2">
            <div class="hear" v-if="rightNavShow">
                <img src="../assets/image/banner1.png" alt="" />
            </div>
            <div class="hear" v-else>
                <img src="../assets/image/banner4.jpg" alt="" />
            </div>
            <div :class="scoll ? 'head_v2' : 'head_v3'">
                <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
                    <el-menu-item index="1">公司介绍</el-menu-item>
                    <el-menu-item index="2">产品介绍</el-menu-item>
                    <el-menu-item index="3">联系方式</el-menu-item>
                </el-menu>
            </div>
            <!-- 内容 -->
            <!-- 公司介绍 -->
            <div class="content" v-if="activeIndex == '1'">
                <span class="indented-text">山东鼎维数字技术有限公司坐落于山东省省会——泉城济南，是一家立足于能源变换和嵌入式软件技术、面向智慧能源、智慧交通、数字化地球的高新技术企业。山东鼎维数字技术有限公司是国家高新技术企业、省市瞪羚企业、“专精特新”企业</span>
                <div style="margin: 10px 0; width: 100%">
                    <video src="../assets/image/introduce.mp4" width="100%" height="100%" controls loop="loop" poster="../assets/image/video.jpg" preload="none">您的浏览器不支持 video 标签</video>
                </div>
                <span class="indented-text">山东鼎维拥有纯正的航天血统，与国内多所重点院校和国家知名航天科技创新研究院深度合作，并依托济南市一企一技术研发中心进行产学研合作。通过了ISO9001质量体系管理认证</span>
                <span class="indented-text"
                    >公司紧抓第三次能源革命和第四次工业革命带来的机遇，发力于能源互联网和云网边端协同的智能化测控系统建设，助力智慧交通、智慧城市和绿色可持续发展国家战略的实现，致力于为智慧地球打造能源底座。产品成功应用于高速公路、桥梁隧道、公园亮化、园区工地、水库河道、农林景区、森林防火的供电和通信系统等多个场景，并已成功获得多项国家专利和计算机软件著作权授权，技术完全自主可控。</span
                >
                <span class="indented-text"
                    >山东鼎维以具有全球化视野的高级管理技术人才为核心，形成一支懂技术、善管理、重科研、年轻稳定的人才宝库。“用精益求精的工匠精神打造产品和服务，为智慧世界、低碳能源、万物互联、安全生活添砖加瓦”是鼎维一以贯之的使命追求。山东鼎维的产品和服务，历经锤炼雕琢，北至东三省，南至闽粤地区，东至鲁苏浙沿海一线、西至新疆甘肃，都能看到它的身影。鼎维还将响应国家粤港澳大湾区的开发策略，逐步进行布点，并将于未来走向一带一路。</span
                >
                <div class="content_tfoot" style="display: flex">
                    <img src="../assets/image/2.jpg" alt="" />
                    <img src="../assets/image/3.png" alt="" />
                    <img src="../assets/image/4.jpg" alt="" />
                    <img src="../assets/image/5.jpg" alt="" />
                </div>
            </div>
            <!-- 产品介绍 -->
            <div class="content" v-if="activeIndex == '2'">
                <div class="demo-image__preview">
                    <div class="content_image">
                        <div style="cursor: pointer; transition: transform 0.3s; border: 1px solid #e1e1e1" class="EPictureimg" v-for="(item, index) in url" :key="index">
                            <el-image :src="item" style="width: 100%; height: 100%" @click="getPrivewImages(index)" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- 联系方式 -->
            <!-- <div class="content arrange" v-if="activeIndex == '3'">
                <div class="right_1">
                    <img src="../assets/image/8.jpg" style="width: 100%" alt="" />
                </div>
                <div class="relation">
                    <div class="relation_span">
                        <h3 class="title_1" style="margin-top: 0">山东鼎维数字技术有限公司</h3>
                        <span>公司官网：<a href="">www.dwdigital.cn</a></span>
                        <span style="display: flex"><span>联系电话：</span><span style="display: inline-block; width: 60%">0531-88898291、13954189296</span></span>
                        <span>电子邮箱：sales@dwdigital.cn</span>
                        <span>公司地址：山东省济南市历城区贞观街988号银丰新能源产业园33号楼3号门</span>
                    </div>
                    <div class="image_1">
                        <div class="relation_img">
                            <img src="../assets/image/6.jpg" style="width: 100%" alt="" />
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="content" v-if="activeIndex == '3'">
                <div style="width: 100%">
                    <img src="../assets/image/7.jpg" style="width: 100%" alt="" />
                </div>
                <div class="relation">
                    <div class="relation_img">
                        <img src="../assets/image/6.jpg" style="width: 100%" alt="" />
                    </div>

                    <div class="relation_span">
                        <span style="font-weight: bold">山东鼎维数字技术有限公司</span>
                        <span>公司官网：<a href="">www.dwdigital.cn</a></span>
                        <span style="display: flex"><span>联系电话：</span><span style="display: inline-block; width: 60%">0531-88898291、13954189296</span></span>
                        <span>电子邮箱：sales@dwdigital.cn</span>
                        <span>公司地址：山东省济南市历城区贞观街988号银丰新能源产业园33-3</span>
                    </div>
                </div>
            </div>
            <!-- 底部 -->
            <div class="content content1">
                <div class="copyright">
                    <div class="w_1366">
                        <span>Copyright © 2021 山东鼎维数字技术有限公司 版权所有</span>
                        <br v-if="!rightNavShow" />
                        <span>
                            <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #666; margin-left: 15px">鲁ICP备19053025号-1</a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <!-- 弹出的大图预览 -->
        <album-big-viewer v-if="BigViewShow" :on-close="closeBigViewShow" :url-list="url" :initialIndex="srcBigIndex" />
    </div>
</template>
<script>
import albumBigViewer from "../../node_modules/element-ui/packages/image/src/album-big-viewer.vue";
export default {
    components: {
        albumBigViewer,
    },
    data() {
        return {
            activeIndex: "1",
            url: [
                "https://dwdigital.cn/HelpShare/ProductPic/1.jpg",
                "https://dwdigital.cn/HelpShare/ProductPic/2.jpg",
                "https://dwdigital.cn/HelpShare/ProductPic/3.jpg",
                "https://dwdigital.cn/HelpShare/ProductPic/4.jpg",
                "https://dwdigital.cn/HelpShare/ProductPic/5.jpg",
                "https://dwdigital.cn/HelpShare/ProductPic/6.jpg",
                "https://dwdigital.cn/HelpShare/ProductPic/7.jpg",
                "https://dwdigital.cn/HelpShare/ProductPic/8.jpg",
                "https://dwdigital.cn/HelpShare/ProductPic/9.jpg",
                "https://dwdigital.cn/HelpShare/ProductPic/10.jpg",
                "https://dwdigital.cn/HelpShare/ProductPic/11.jpg",
                "https://dwdigital.cn/HelpShare/ProductPic/12.jpg",
                "https://dwdigital.cn/HelpShare/ProductPic/13.jpg",
                "https://dwdigital.cn/HelpShare/ProductPic/14.jpg",
                "https://dwdigital.cn/HelpShare/ProductPic/15.jpg",
                "https://dwdigital.cn/HelpShare/ProductPic/16.jpg",
                "https://dwdigital.cn/HelpShare/ProductPic/17.jpg",
                "https://dwdigital.cn/HelpShare/ProductPic/18.jpg",
                "https://dwdigital.cn/HelpShare/ProductPic/19.jpg",
                "https://dwdigital.cn/HelpShare/ProductPic/20.jpg",
                "https://dwdigital.cn/HelpShare/ProductPic/21.jpg",
                // require("../assets/image/product/1.jpg"),
                // require("../assets/image/product/2.jpg"),
                // require("../assets/image/product/3.jpg"),
                // require("../assets/image/product/4.jpg"),
                // require("../assets/image/product/5.jpg"),
                // require("../assets/image/product/6.jpg"),
                // require("../assets/image/product/7.jpg"),
                // require("../assets/image/product/8.jpg"),
                // require("../assets/image/product/9.jpg"),
                // require("../assets/image/product/10.jpg"),
                // require("../assets/image/product/11.jpg"),
            ],
            scoll: false,
            BigViewShow: false,
            srcBigIndex: 0, //大图展示当前索引
            rightNavShow: true,
        };
    },
    created() {
        this.isMobile();
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        isMobile() {
            const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
            if (flag === null) {
                console.log("pc端");
                this.rightNavShow = true;
            } else {
                console.log("移动端");
                this.rightNavShow = false;
            }
        },
        handleSelect(index) {
            this.activeIndex = index;
        },
        handleScroll() {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            const offset = 20; // 设置一个偏移量，当滚动超过这个偏移量时固定div
            this.scoll = scrollTop > offset;
        },
        getPrivewImages(index) {
            this.srcBigIndex = index;

            this.BigViewShow = true;
        },
        //关闭大图预览
        closeBigViewShow() {
            this.BigViewShow = false;
        },
    },
};
</script>
<style scoped>
.parties {
    display: flex;
    justify-content: center;
    align-items: center;
}
.box {
    width: 98vw;
    height: 100%;
    background: #fff;
}
@media (max-width: 768px) {
    .box {
        width: 100vw; /* 移动端调整为 100% 视口宽度 */
        height: auto; /* 高度自适应 */
    }
}
.box2 {
    width: 60vw;
    height: 100%;
}
@media (max-width: 768px) {
    .box2 {
        width: 90vw; /* 移动端调整为 90% 视口宽度 */
    }
}

.hear {
    padding: 0.25rem 0;
    z-index: 9;
    margin: auto;
}
@media (max-width: 768px) {
    .hear {
        padding: 0.1rem 0; /* 移动端调整内边距 */
    }
}
.hear img {
    max-width: 100%; /* 图片最大宽度为父容器宽度 */
    height: auto; /* 高度自适应 */
    display: block; /* 避免图片下方有空白间隙 */
}

.head_v3 {
    position: fixed;
    top: 5.25rem;
    width: 100vw;
    left: 0;
    z-index: 99;
    -webkit-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
    /* background-color: #1f52a094; */
}
@media (max-width: 768px) {
    .head_v3 {
        top: 4.8rem;
    }
}
.head_v2 {
    position: fixed;
    top: 0;
    width: 100vw;
    left: 0;
    z-index: 99;
    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
    background: rgba(255, 255, 255, 0.9);
}

.copyright {
    /* background: url(../images/foot_2.jpg) left bottom repeat-x; */
    padding: 0.75rem;
    box-sizing: border-box;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0; /* 关键修改点1：水平居中基准 */
    background: #eee;
    color: #666;
    /* 如果需要背景图居中，可添加： */
    background-position: center;
    /* 如果需要内容居中，可添加： */
    text-align: center;
    font-size: 0.8rem;
}
.w_1366 {
    margin: auto;
}
.el-menu.el-menu--horizontal {
    border-bottom: none;
}
.el-menu {
    background: #2056a3;
    color: #fff;
    display: flex;
    justify-content: center;
}
.el-menu--horizontal > .el-menu-item {
    color: #fff;
}
.el-menu-item {
    padding: 0 10.25rem;
}
@media (max-width: 768px) {
    .el-menu-item {
        padding: 0 2.25rem;
    }
}
.el-menu--horizontal > .el-menu-item {
    height: 2.75rem;
    line-height: 2.75rem;
}
.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
    /* background-color: #fff; */
    background: none;
    color: #fff;
}
.el-menu--horizontal > .el-menu-item.is-active {
    border-bottom: 0.15rem solid #fff;
}
.el-menu--horizontal > .el-menu-item.is-active {
    color: #fff;
}
.content {
    width: 100%;
    height: 100%;
    margin: 70px 0;
    position: relative;
}
@media (max-width: 768px) {
    .content {
        width: 90vw; /* 移动端调整为 90% 视口宽度 */
        height: 100%;
        margin: 3.5rem 0;
        position: relative;
    }
    .content1 {
        margin: 5rem 0;
    }
}
.content_image {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.EPictureimg:hover {
    transform: scale(1.1); /* 鼠标悬停时放大到1.2倍 */
}
/* .EPictureimg:nth-child(1) {
    width: 45%;
    height: 50%;
    margin: 0 0 30px 2%;
}
.EPictureimg:nth-child(2) {
    width: 45%;
    height: 50%;
    margin: 0 0 30px 2%;
}
.EPictureimg:nth-child(n + 3) {
    width: 30%;
    height: 50%;
    margin: 0 0 30px 2%;
} */
.EPictureimg {
    width: 30%;
    height: 50%;
    margin: 0 0 30px 2%;
}
@media (max-width: 768px) {
    /* .EPictureimg:nth-child(1) {
        width: 100%;
        height: 50%;
        margin: 0 0.5rem 1rem 0.5rem;
    }
    .EPictureimg:nth-child(2) {
        width: 100%;
        height: 50%;
        margin: 0 0.5rem 1rem 0.5rem;
    }
    .EPictureimg:nth-child(n + 3) {
        width: 80%;
        height: 50%;
        margin: 0 10% 1rem 10%;
    } */
    .EPictureimg {
        width: 80%;
        height: 50%;
        margin: 0 10% 1rem 10%;
    }
}
.indented-text {
    text-indent: 2em; /* 使用em单位可以实现与字体大小相关的缩进 */
    display: inline-block;
    text-align: justify;
    line-height: 40px;
}
@media (max-width: 768px) {
    .indented-text {
        line-height: 2rem;
    }
}
.arrange {
    display: flex;
    justify-content: center;
}
/* .image_1 {
    width: 12rem;
    height: 12rem;
    background: #f3f3f3;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.6rem;
} */
.image_1 {
    margin-top: 1rem;
}
.right_1 {
    width: 40%;
}
.relation {
    width: 100%;
    margin-top: 20px;
    border: 1px solid #e1e1e1;
    display: flex;
}

.relation_img {
    width: 10.8rem;
    height: 10.8rem;
    text-align: center;
    border-radius: 1rem;
    overflow: hidden;
}
@media (max-width: 768px) {
    .right_1 {
        width: 100%;
    }
    .arrange {
        display: flex;
        flex-direction: column;
    }
    .relation {
        width: 100%;
        margin-top: 20px;
        padding: 0.75rem;
        border: 1px solid #e1e1e1;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        box-sizing: border-box;
    }
    .image_1 {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    /* .relation_img {
        width: 50%;
        height: 50%;
    } */
}
.title_1 {
    font-size: 20px;
    letter-spacing: 1.3px;
    color: #000;
}
/* .title_2 {
    letter-spacing: 0.3px;
    margin: 0.75rem 0 0.35rem 0;
    font-weight: 600;
    font-size: 16px;
    color: #000;
} */
.relation_span {
    padding-left: 0.5rem;
}

.relation_span span {
    display: block;
    line-height: 2rem;
    text-align: left;
    color: #797878;
    font-size: 14px;
}

.content_tfoot {
    width: 100%;
}
.content_tfoot img {
    width: 22%;
    margin: 0 2%;
    height: auto;
    object-fit: contain;
}
/* @media screen and (max-width: 750px) {
    .box2 {
        width: 600px;
        height: 100%;
    }
} */
</style>
