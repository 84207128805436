<template>
    <div id="app">
        <HelloWorld />
    </div>
</template>

<script>
import HelloWorld from "./components/HelloWorld.vue";

export default {
    name: "App",
    components: {
        HelloWorld,
    },
};
</script>

<style>
body {
    margin: 0;
}
#app {
    /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center; */
    color: #2c3e50;
    width: 100%;
    height: 100%;
    overflow: hidden;
    font-size: 1rem;
}
</style>
